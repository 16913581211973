import React, { useState, useContext } from "react";
import {
  MdOutlinePeopleAlt,
  MdOutlinePerson,
  MdVerifiedUser,
  MdKey,
  MdEdit,
} from "react-icons/md";
import _ from "lodash";
import { UserContext } from "../../../UserContext";
import { useMembers, useMembersToast } from "../MembersContext";
import { patchData } from "../../../utils/api";
import { getMonthYear } from "../../../utils/formatDate";
import Avatar from "../../../elements/Avatar";
import Button from "../../../elements/Button";
import Slideout from "../../../elements/Slideout";
import Dropdown from "../../../elements/Dropdown";
import Toggle from "../../../elements/Toggle";
import Tag from "../../../elements/Tag";
import { Esp, Ovi, RoundTable } from "../../../Logos";

const options = [
  {
    value: "user",
    label: "Member",
    info: "The user has access to this application",
    icon: <MdOutlinePerson />,
  },
  {
    value: "admin",
    label: "Admin",
    info: "The user can manage team members for this application",
    icon: <MdOutlinePeopleAlt />,
  },
];

export default function EditUser({ trigger, member }) {
  const initialProducts = {
    esp: hasAccessTo("esp"),
    roundtable: hasAccessTo("roundtable"),
    ovi: hasAccessTo("ovi"),
  };
  const initialRole = options.find((option) => option.value === member.role);
  const initialName = { first: member.first_name, last: member.last_name };
  const { currentUser } = useContext(UserContext);
  const { dispatch } = useMembers();
  const { dispatchToast } = useMembersToast();
  const [products, setProducts] = useState(initialProducts);
  const [selectedOption, setSelectedOption] = useState(initialRole);
  const [name, setName] = useState(initialName);

  const isSubmitDisabled =
    _.isEqual(products, initialProducts) &&
    selectedOption.value === member.role &&
    member.name === `${name.first} ${name.last}`;
  async function handleEdit() {
    const payload = {
      id: member.id,
      permissions: {
        role: selectedOption.value,
        products,
      },
      first_name: name.first,
      last_name: name.last,
    };

    try {
      const response = await patchData(
        `/${currentUser.organizationId}/update`,
        payload,
      );
      dispatch({
        type: "edit_permissions",
        payload: { member: response.user },
      });
      dispatchToast({
        type: "success_toast",
        payload: response.notice || "Successfully updated user permissions",
      });
    } catch (error) {
      console.log(error);
      dispatchToast({ type: "error_toast" });
    }
  }

  function hasAccessTo(productName) {
    return (
      member.products.findIndex((product) => product.name === productName) !==
      -1
    );
  }

  function toggleProductAccess(productName) {
    setProducts((prevProducts) => ({
      ...prevProducts,
      [productName]: !prevProducts[productName],
    }));
  }

  function handleCancel() {
    setProducts(initialProducts);
    setSelectedOption(initialRole);
  }

  return (
    <Slideout
      trigger={trigger}
      confirm={
        <Button
          utilityClasses="ph--8 br--pill lh--tight flex--item-grow1"
          isDisabled={isSubmitDisabled}
          onClick={handleEdit}
        >
          Save Changes
        </Button>
      }
      cancel={
        <Button
          variant="secondary"
          utilityClasses="ph--24 br--pill lh--tight flex--item-grow1"
        >
          Cancel
        </Button>
      }
      handleCancel={handleCancel}
    >
      <Header name={name} handleNameChange={setName} member={member} />
      <Dropdown
        selectedOption={selectedOption}
        options={options}
        onSelect={(option) => setSelectedOption(option)}
      />
      <section className="bg--elevation-3 pv--16 ph--24 br--12">
        <h4 className="display--flex flex--items-center gap--8 mb--24 fs--16">
          <MdKey /> Product Access
        </h4>
        <div className="display--flex flex--column gap--24">
          <Toggle
            label={
              <ToggleLabel logo={<Esp width={16} height={16} />} label="ESP" />
            }
            isOn={products.esp}
            handleToggle={() => toggleProductAccess("esp")}
            disabled={!currentUser.mfrPermissions.esp}
          />
          <Toggle
            label={
              <ToggleLabel
                logo={<RoundTable width={16} height={16} />}
                label="Roundtable"
              />
            }
            isOn={products.roundtable}
            handleToggle={() => toggleProductAccess("roundtable")}
            disabled={!currentUser.mfrPermissions.roundtable}
          />
          <Toggle
            label={
              <ToggleLabel logo={<Ovi width={16} height={16} />} label="OVI" />
            }
            isOn={products.ovi}
            handleToggle={() => toggleProductAccess("ovi")}
            disabled={!currentUser.mfrPermissions.ovi}
          />
        </div>
      </section>
    </Slideout>
  );
}

function Header({ name, handleNameChange, member }) {
  const { initials, joinedAt, activation_state } = member;
  const { first, last } = name;
  const [isNameEditOpen, setIsNameEditOpen] = useState(false);

  const handleInputChange = (e) => {
    handleNameChange((prevName) => ({
      ...prevName,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <header className="pt--32 pb--24 ph--24 br--12 bg--elevation-3">
      <section className="display--flex flex--column flex--items-center gap--16">
        <Avatar initials={initials} size="lg" />
        <div className="display--flex gap--4">
          <h2 className="fs--24 fw--600 lh--tight">
            {first} {last}
          </h2>
          {activation_state !== "active" && (
            <Button
              variant="tertiary"
              onClick={() => setIsNameEditOpen(!isNameEditOpen)}
            >
              <MdEdit size={16} />
            </Button>
          )}
        </div>
        {isNameEditOpen && (
          <div>
            <input
              id="first"
              name="first"
              className="br--8 border--none ph--16 pv--20 bg--dialog-input white w--100 fs--16 focus--outline-white"
              type="text"
              placeholder="First Name*"
              required
              autoFocus
              defaultValue={first}
              onChange={handleInputChange}
            />
            <input
              id="last"
              name="last"
              className="br--8 border--none ph--16 pv--20 bg--dialog-input white w--100 fs--16 focus--outline-white"
              type="text"
              placeholder="Last Name*"
              required
              defaultValue={last}
              onChange={handleInputChange}
            />
          </div>
        )}
        <div className="display--flex gap--8">
          <Tag utilityClasses="bg--none border bc--badge">
            <MdOutlinePeopleAlt size={14} />
            <span>Joined {getMonthYear(joinedAt)}</span>
          </Tag>
          <Tag utilityClasses="bg--none border bc--badge">
            <MdVerifiedUser color="#24b668" size={14} />{" "}
            <span>
              <strong>2fa:</strong> On
            </span>
          </Tag>
        </div>
      </section>
    </header>
  );
}

function ToggleLabel({ logo, label }) {
  return (
    <div className="display--flex flex--center gap--12">
      <span className="display--inline-flex bg--black-v3 br--6 pa--8">
        {logo}
      </span>
      <span className="fw--300">{label}</span>
    </div>
  );
}
